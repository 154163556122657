.paddingall {
  padding: 10px 10px 10px 10px;
}

.pricetitle {
  font-weight: 600;
}

.pricesubtitle {
  font-size: 14px;
}

.iconstyle {
  margin-right: 20px;
  margin-top: 10px;
  color: white;
}

.iconstyleblack {
  margin-right: 20px;
  margin-top: 10px;
  color: black;
  color: #888888;
}

.gradiant {
  background: rgb(79, 211, 213);
  background: linear-gradient(
    158deg,
    rgb(46, 93, 194) 0%,
    rgb(97, 63, 151) 100%
  );
}

.gradiantDark {
  background: rgb(95, 67, 255);
  background: linear-gradient(
    158deg,
    rgb(130, 51, 158) 0%,
    rgb(54, 243, 234) 100%
  );
}

.gradiantLight {
  background: rgb(114, 177, 181);
  background: linear-gradient(
    158deg,
    rgb(81, 125, 218) 0%,
    rgb(119, 67, 250) 100%
  );
}

.bgcolor {
  min-height: 100%;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  margin: 4px;
}

.card:hover {
  background-color: #c9e4fe;
  border: 1px solid #177cdd;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  animation: timeLike 0.1s ease-in-out forwards;
}

@keyframes timeLike {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-4px);
  }
}

.addIcon:hover {
  animation: zoom 0.3s forwards;
  color: rgb(3, 21, 217) !important;
}

.ListIcon {
  color: rgb(0, 0, 0) !important;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}


