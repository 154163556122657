.btn-save {
  border-radius: 5px;
  cursor: pointer;
}
.btn-clone {
  border-radius: 5px;
  cursor: pointer;
}
.btn-print {
  border-radius: 5px;
  cursor: pointer;
}

.btn-more-info {
  border-radius: 5px 19px 5px 5px;
  cursor: pointer;
}

@media (max-width: 599px) {
  .btn-more-info {
    border-radius: 5px !important;
  }

  .btn-print {
    border-radius: 5px 19px 5px 5px !important;
  }
}

@media (max-width: 427px) {
  .btn-print {
    border-radius: 5px !important;
  }
  .btn-clone {
    border-radius: 5px 19px 5px 5px !important;
  }
}
@media (max-width: 380px) {
  .Lift-Name {
    max-width: 150px !important;
  }
}

@media (max-width: 333px) {
  .btn-clone {
    border-radius: 5px !important;
  }
  .btn-save {
    border-radius: 5px 19px 5px 5px !important;
  }

  .Lift-Name {
    max-width: 150px !important;
  }
  .lift-qty {
    padding-left: 16px !important;
  }
}

@media (max-width: 600px) {
  .fix-Btn-Header-ProposalDas {
    margin-top: -35px !important;
    padding: 10px 10px 10px 60px !important;
  }
}

@media (max-width: 504px) {
  .fix-Btn-Header-ProposalDas {
    margin-top: -76px !important;
    padding: 10px 10px 10px 60px !important;
    /* margin-bottom: 10px !important; */
  }
  .Unfreez-ProposalDas {
    margin-top: 50px !important;
  }
}

@media (max-width: 450px) {
  .fix-Btn-Header-ProposalDas {
    margin-top: -76px !important;
    padding: 20px 10px 10px 60px !important;
  }
  .Unfreez-ProposalDas {
    margin-top: 50px !important;
  }
}
@media (max-width: 400px) {
  .fix-Btn-Header-ProposalDas {
    margin-top: -70px !important;
    padding: 20px 10px 10px 60px !important;
    margin-bottom: 10px !important;
  }
  .Unfreez-ProposalDas {
    margin-top: 50px !important;
  }
}
