@media (max-width: 4433px) {
  /* .table {
    width: 95%;
    margin-left: 20px;
    padding: 10px 0px;
  }
  .maintable {
    width: 75vw;
  }
  .mainBox {
    width: 200px;
  } */
  .table {
    height: auto;
    width: 99%;
    margin-left: 20px;
    padding: 10px 0px;
  }

  .maintable {
    width: 100%;
  }

  .DesktopTable {
    width: 100%;
  }

  .smalltable {
    width: 50%;
  }

  .midiumtable {
    width: 70%;
  }

  .mainBox {
    width: 120px;
  }
}

.MobileTable {
  display: none;
}

@media (max-width: 800px) {
  .mainBox {
    z-index: 1;
    position: absolute;
    width: 100%;
    padding-left: 80px !important;
  }
  .smalltable {
    width: 100%;
  }
  .midiumtable {
    width: 100%;
  }

  .DesktopTable {
    display: none;
  }

  .MobileTable {
    display: block;
  }
}
