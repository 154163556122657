.primary {
  background-color: #3179ff !important;
  color: white !important;
}

.primary-button {
  background-color: #143ee6;
  color: white;
}

.child-button {
  background-color: white !important;
  color: #1976d2 !important;
}

.disable-button-cursor {
  cursor: no-drop !important;
}

.disable-button {
  background-color: rgb(227, 227, 227) !important;
  color: rgb(112, 112, 112) !important;
}

.new-button {
  /* background-color: #fec917 !important; */
  /* background-color: #ff9100 !important; */
  background-color: #e0ae0d !important;
  color: white !important;
}

.pdf-button {
  /* background-color: #fec917 !important; */
  /* background-color: #ff9100 !important; */
  background-color: #f29129 !important;
  color: white !important;
}

.print-button {
  background-color: #269605 !important;
  color: white !important;
}

.secondary-button {
  background-color: #177cdd !important;
  color: white !important;
}

.delete-button {
  background-color: #fa4e4e !important;
  color: white !important;
}

.excel-button {
  background-color: #c3fdb8 !important;
  /* color: rgb(18, 168, 95) !important; */
  /* background-color: white !important; */
  color: black !important;
}

.primary-active-list-item {
  background-color: #abd0f7 !important;
}

.white-active-list-item {
  background-color: #e3e3e3 !important;
}

.primary-inactive-list-item {
  background-color: transparent;
}

.primary-bgcolor100-with-border {
  background-color: #ecf4fd;
  border: 2px solid #1c7dde;
}


